.HeaderButton {
  background: url("modules/cp_membersAndPermissions/assets/images/btn_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;

  width: 18%;
  padding: 7px 0;
  border-radius: 12px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderButton svg {
  width: 30px;
  color: var(-white);
}
.newMember {
  color: var(--components-bg-rgba);
  padding: 7px 0;
  font-size: 14px;
  display: none;
}

@media (min-width: 992px) {
  .HeaderButton {
    width: 20%;
  }
  .HeaderButton svg {
    display: none;
  }
  .newMember {
    display: block;
  }
}
