.Button {
  max-width: var(--Button-max-width);
  min-width: var(--Button-min-width);

  padding: var(--padding);

  border-radius: calc(var(--border-radius) / 2);
  border-width: 1px;
  border-style: solid;

  transition: var(--transition);
}

.outline {
  background-color: transparent;
  color: var(--action-border-rgba);

  border-color: var(--action-border-rgba);
}

.outline:hover {
  background-color: var(--action-border-rgba);
  color: var(--components-bg-rgba);
}

.solid {
  background-color: var(--action-bg-rgba);
  color: var(--components-bg-rgba);
}

.solid:hover {
  background-color: transparent;
  color: var(--action-bg-rgba);
}
