.CircleImg {
  border-radius: 50%;
  overflow: hidden;

  border: 1px solid var(--inner-status-rgba);

  /* To adjust the alt content */
  display: grid;
  align-content: center;
  justify-content: center;
}

.defaultWidth {
  width: 50%;
}

.width100 {
  width: 100px;
  height: 100px;
}
