.Flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gap {
  gap: var(--gap);
}

.wrap {
  flex-wrap: wrap;
}

.center {
  justify-content: center;
}
