/* ************************************AddArea************************************* */
.AddArea {
  width: 100%;
  text-align: right;
}

.AddArea h2 {
  color: var(--black);
  font-size: 20px;
  margin: 20px 0;
}

.AddArea_inputs {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.inputField {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.inputField label {
  margin-bottom: 10px;
  color: var(--inputs-label-rgba);
  font-size: 14px;
}

.inputField input {
  padding: 10px;
  border-radius: 8px;
  border: 0.09rem solid var(--inputs-border-rgba);
  background-color: var(--white);
  color: var(--black);
}

.inputField input:focus {
  border: 0.09rem solid var(--orange);
}

/* ************************************CountryPlaces************************************* */
.CountryPlaces {
  text-align: right;
  margin-top: 20px;
}

.areas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.areas .area {
  width: 100%;
  border-radius: 12px;
  background-color: var(--white);
  margin-bottom: 40px;
}

.areas .area .areaImg {
  width: 100%;
}

.areas .area .areaImg img {
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.areas .area .areaContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.areas .area .areaContent .areaContentLocation {
  width: 15%;
}

.areas .area .areaContent .areaContentName {
  width: 65%;
  justify-self: flex-end;
  font-size: 20px;
}

.areas .area .areaContent .areaContentEagle {
  width: 15%;
}

/* ************************************CountryMembers************************************* */
.CountryMembers {
  text-align: center;
  margin-top: 20px;
}

.CountryMembersHeading {
  text-align: right;
}

.members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.members .member {
  width: 100%;
  border-radius: 12px;
  background-color: var(--white);
  margin-bottom: 20px;
  padding: 20px;
}

.members .member .memberImg {
  width: 40%;
  margin: auto;
}

.members .member .memberImg img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.members .member h2 {
  margin-top: 5px;
  font-size: 19px;
}

.members .member h3 {
  margin-top: 5px;
  font-size: 18px;
}

.members .member .memberBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.members .member .memberBtns button {
  padding: 8px 0px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 15pt;
  font-weight: normal;
  width: 45%;
}

.members .member .memberBtns button:nth-child(1) {
  background-color: transparent;
  border: 1.5pt solid var(--green2);
  color: var(--green2);
}

.members .member .memberBtns button:nth-child(1):hover {
  background-color: var(--green2);
  color: var(--white);
  cursor: pointer;
}

.members .member .memberBtns button:nth-child(2) {
  background-color: var(--green1);
  color: var(--white);
}

.members .member .memberBtns button:nth-child(2):hover {
  background-color: transparent;
  color: var(--green1);
  border: 1.5pt solid var(--green1);
  cursor: pointer;
}

.areasAndPlacesBTN {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.areasAndPlacesBTN button {
  width: 60%;
  border: none;
  outline: none;
  background-color: var(--green1);
  color: var(--white);
  border-radius: 8px;
  padding: 15px;
}

@media (min-width: 430px) {
  .areas .area {
    width: 47%;
    margin-bottom: 20px;
  }

  .areas .area .areaContent {
    padding: 20px 10px;
  }

  .areas .area .areaContent .areaContentLocation {
    width: 12%;
  }

  .areas .area .areaContent .areaContentName {
    width: 70%;
    font-size: 13px;
  }

  .areas .area .areaContent .areaContentEagle {
    width: 12%;
  }

  .members .member {
    width: 47%;
  }

  .members .member h2 {
    font-size: 15px;
  }

  .members .member h3 {
    font-size: 14px;
  }

  .members .member .memberBtns {
    margin-top: 20px;
  }

  .members .member .memberBtns button {
    padding: 7px 0px;
    font-size: 12pt;
  }

  .areasAndPlacesBTN button {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .inputField {
    width: 48%;
  }

  .inputField:nth-child(3) {
    width: 100%;
  }

  .areas .area {
    width: 30%;
  }

  .areas .area .areaContent .areaContentLocation {
    width: 12%;
  }

  .areas .area .areaContent .areaContentName {
    width: 70%;
    font-size: 13px;
  }

  .areas .area .areaContent .areaContentEagle {
    width: 12%;
  }

  .members .member {
    width: 30%;
  }

  .areasAndPlacesBTN button {
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .inputField {
    width: 32%;
  }

  .inputField:nth-child(3) {
    width: 32%;
  }

  .areas .area {
    width: 23%;
  }

  .areas .area .areaContent .areaContentLocation {
    width: 12%;
  }

  .areas .area .areaContent .areaContentName {
    width: 70%;
  }

  .areas .area .areaContent .areaContentEagle {
    width: 12%;
  }

  .members .member {
    width: 23%;
  }

  .members .member .memberBtns button {
    padding: 10px 0;
    font-size: 8pt;
  }

  .areasAndPlacesBTN button {
    width: 30%;
  }
}
